import './App.scss';
import ContactUs from "./components/ContactForm";
import Intro from "./components/Intro";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Footer from "./components/Footer";

function App() {
  return (
      <div className="App">
        <Header />
        <Hero />
        <Intro />
        <div className="col-right">
          <ContactUs />
        </div>
        <Footer />
      </div>
  );
}

export default App;
