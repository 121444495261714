import React from "react";


export default function Intro() {
  return (
      <>
        <div className="intro_inner_wrapper_left">
          <h1>Buche einen <br/>
            Termin <br/> mit Studio August</h1>
          <h1 className="mobile-text">Buche einen
            Termin<br/> mit Studio August</h1>
        </div>


        <div className="intro_inner_wrapper_right default-copy">
          <div className="intro_spacer_wrapper">
            <p className="m-0">Bitte fülle folgende Fragen aus, damit wir dir so schnell als möglich einen passenden Termin für dich finden können</p>


            <p>
              Wir sprechen englisch und deutsch.
            </p>

              <p>Bitte habe Verständnis, dass eine Antwort bis zu 7 Tagen dauern kann.</p>


            <p>Wir freuen uns von dir zu hören!</p>


            <p className="reminder-section"> ⃰damit wir uns nicht verfehlen, bitte denke immer daran, auch deinen Spam-Ordner zu checken.</p>
          </div>

        </div>
      </>
  );
}
